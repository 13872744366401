import React, { useEffect } from 'react';
// import { navigate } from 'gatsby';

import Layout from '../components/layout';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import '@stripe/stripe-js';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { CartProvider } from 'use-shopping-cart/react';
import { purchaseEventGA } from '../utils/ga-events';
import { purchaseEventFB } from '../utils/fb-events';

const ThankYouPage = ({ location }) => {
  const images = useStaticQuery(graphql`
    query {
      AppBannerImage: file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "app-banner" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 500
            placeholder: BLURRED
            webpOptions: { quality: 30 }
          )
        }
        name
      }
      QrAppImage: file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "qr2googleANDappleStores" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 100
            placeholder: BLURRED
            webpOptions: { quality: 30 }
          )
        }
        name
      }
    }
  `);

  // const getCheckoutInfo = async (sessionID) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     'Authorization',
  //     `Bearer ${process.env.STRIPE_SECRET_KEY}`
  //   );

  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow',
  //   };

  //   const checkoutResponse = await fetch(
  //     `https://api.stripe.com/v1/checkout/sessions/${sessionID}`,
  //     requestOptions
  //   );
  //   const checkoutData = await checkoutResponse.json();
  //   const lineItemsResponse = await fetch(
  //     `https://api.stripe.com/v1/checkout/sessions/${sessionID}/line_items`,
  //     requestOptions
  //   );
  //   const lineItems = await lineItemsResponse.json();
  //   const lineItemsData = lineItems.data;

  //   return { checkoutData, lineItemsData };
  // };

  // Function to perform the serverless checkout. We use netlify function for this.
  async function getCheckoutInfo(sessionID) {
    const response = await fetch(
      `${process.env.NETLIFY_FUNCTIONS_URL}checkoutInfo`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionID: sessionID,
        }),
      }
    );

    const checkoutData = await response.json();

    purchaseEventGA(checkoutData.sessionData, checkoutData.lineItemsData);
    purchaseEventFB(checkoutData.sessionData, checkoutData.lineItemsData);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionID = params.get('session_id');
    getCheckoutInfo(sessionID);
  });

  // const [value, setValue] = React.useState('');

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const url = 'https://hook.eu1.make.com/rkta6vpw6r0g9z1dr5gyp454j01psoe9';
  //   const request = new XMLHttpRequest();
  //   request.open('POST', url, true);
  //   request.onload = function () {
  //     // request successful
  //     // we can use server response to our request now
  //     navigate('https://customsurgical.co');
  //   };

  //   request.onerror = function () {
  //     // request failed
  //   };

  //   request.send(new FormData(event.target)); // create FormData from form that triggered event
  // };

  return (
    <main style={{ marginTop: 100 }}>
      <CartProvider stripe={process.env.STRIPE_PUBLISHABLE_KEY} currency="EUR">
        {/* <Container id="thankyou-page" maxWidth={'lg'} sm={6}> */}
        <Layout helmetName="Thank you">
          <Container maxWidth={'lg'}>
            <Grid
              container
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              // padding={3}
              // margin={3}
              // spacing={4}
            >
              <Grid item xs={3}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: 80, color: '#FFA100' }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" gutterBottom={true}>
                  Thank you!
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: 'justify' }}
                  gutterBottom={true}
                >
                  We have succesfully received your order, and you will receive
                  it shortly.
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  paddingTop={{ xs: 1, sm: 3 }}
                  paddingBottom={{ xs: 1, sm: 3 }}
                  justifyContent={'center'}
                >
                  <Box
                    sx={{ backgroundColor: '#FFA100', borderRadius: '5px' }}
                    padding={3}
                    height={'100%'}
                    width={'100%'}
                  >
                    <Box
                      marginTop={3}
                      marginBottom={3}
                      display={{ xs: 'none', sm: 'flex' }}
                      justifyContent={'flex-end'}
                    >
                      <Typography
                        variant="h4"
                        textAlign="right"
                        gutterBottom={true}
                      >
                        Enjoy the full potential of the
                      </Typography>
                    </Box>
                    <Box
                      marginTop={3}
                      marginBottom={3}
                      display={{ xs: 'flex', sm: 'none' }}
                    >
                      <Typography variant="h4" gutterBottom={true}>
                        Enjoy the full potential of&nbsp;the{' '}
                        <span style={{ color: '#F9F9F9', fontWeight: 'bold' }}>
                          MicroREC
                        </span>
                      </Typography>
                    </Box>
                    <Box marginLeft={{ xs: 0, sm: 10 }} marginBottom={3}>
                      <Typography variant="body1" gutterBottom={true}>
                        In the meantime, you can download our MicroREC App here:
                      </Typography>
                    </Box>
                    <Box
                      display={{ xs: 'none', sm: 'flex' }}
                      sx={{
                        backgroundColor: '#F9F9F9',
                        borderRadius: '5px',
                        width: 150,
                        height: 150,
                      }}
                      alignItems={'center'}
                      justifyContent={'center'}
                      marginLeft={20}
                    >
                      <GatsbyImage
                        image={getImage(images.QrAppImage)} fadeIn={false} loading="eager"
                        key={images.QrAppImage.name}
                      />
                    </Box>

                    <Box display={{ xs: 'flex', sm: 'none' }}>
                      <a
                        href="http://onelink.to/nqua9j"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#F9F9F9' }}
                        >
                          Download MicroREC App
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  paddingTop={{ xs: 1, sm: 3 }}
                  paddingBottom={{ xs: 1, sm: 3 }}
                >
                  <Box
                    sx={{ backgroundColor: '#F9F9F9', borderRadius: '5px' }}
                    padding={3}
                    height={'100%'}
                  >
                    <Grid
                      item
                      marginTop={3}
                      display={{ xs: 'none', sm: 'flex' }}
                    >
                      <Typography
                        variant="h4"
                        color={'primary'}
                        textAlign="left"
                        gutterBottom={true}
                        sx={{ fontWeight: 'bold' }}
                      >
                        MicroREC
                      </Typography>
                    </Grid>
                    <GatsbyImage
                      image={getImage(images.AppBannerImage)} fadeIn={false} loading="eager"
                      key={images.AppBannerImage.name}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                xs={10}
                sm={4}
                marginTop={3}
                marginBottom={3}
                padding={6}
                sx={{
                  borderRadius: '5px',
                  boxShadow: ' 0px 4px 9px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#F9F9F9',
                  paddingTop: 5,
                  paddingBottom: 5,
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
              >
                <form
                  method="post"
                  // action="https://hook.eu1.make.com/rkta6vpw6r0g9z1dr5gyp454j01psoe9"
                  onSubmit={handleSubmit}
                >
                  <Grid item paddingBottom={2}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Where did you hear from us?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={value}
                        onChange={handleChange}
                        name="ecommerce-hear-from-us"
                      >
                        {hearFrom.map((item) => {
                          return (
                            <FormControlLabel
                              value={item}
                              control={<Radio />}
                              label={item}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </form>
              </Grid> */}
              {/* <Box
                alignItems={'center'}
                component="form"
                // action="https://hook.eu1.make.com/rkta6vpw6r0g9z1dr5gyp454j01psoe9"
                // onSubmit={}
                marginTop={3}
                sx={{
                  borderRadius: '5px',
                  boxShadow: ' 0px 4px 9px rgba(0, 0, 0, 0.15)',
                  backgroundColor: '#F9F9F9',
                  paddingTop: 5,
                  paddingBottom: 5,
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <InputLabel id="demo-multiple-name-label" shrink={false}>
                  Where did you hear from us?
                </InputLabel>
                <Select
                  size="md"
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  // multiple
                  label=" Where did you hear from us?"
                  value={personName}
                  onChange={handleChange}
                  // input={<OutlinedInput label="Name" />}
                  // MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Button type="submit">Submit</Button>
              </Box> */}
            </Grid>
          </Container>
        </Layout>
      </CartProvider>
    </main>
  );
};

export default ThankYouPage;
